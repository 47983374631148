@import "../../assets/styles/utils";

.nybanner{
    display: flex;
    align-items: center;
    text-align: right;
    position: relative;
    overflow: hidden;
    background-color: $color-main;
    @include res(margin-top,$header-height-base,$header-height-ratio);
    @include res(height,580px,(md:400px,sm:300px,xs:200px));
    @include res(padding-right,176px, 15 / 176);
    
    .nybanner-bg{
        height: 100%;
        width: 100%;
        position: relative;
        &::after{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.2);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        b{
            display: block;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }
    }
    
    h1{
        position: absolute;
        top: 50%;
        left: 0;
        color: #fff;
        transform: translateY(-50%);
        z-index: 2;
        font-weight: bold;
        text-transform: uppercase;
        @include res(font-size,60px, 30 / 60);
        @include res(padding-left,176px, 15 / 176);
    }
}

@media screen and (max-width: 992px) {
    .nybanner{
        padding-right: 70px;
    }
}
@media screen and (max-width: 767px) {
    .nybanner{
        padding-right: 0px;
    }
}