@font-face {
  font-family: "iconfont"; /* Project id 3007970 */
  src: url('iconfont.woff2?t=1640327509882') format('woff2'),
       url('iconfont.woff?t=1640327509882') format('woff'),
       url('iconfont.ttf?t=1640327509882') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-luntai:before {
  content: "\e671";
}

.icon-search_light:before {
  content: "\e7da";
}

.icon-world:before {
  content: "\e620";
}

.icon-facebook-fill:before {
  content: "\e88d";
}

.icon-linkedin-fill:before {
  content: "\e890";
}

.icon-jiantou_you:before {
  content: "\eb03";
}

.icon-jiantou_xia:before {
  content: "\eb04";
}

.icon-jiantou_zuo:before {
  content: "\eb05";
}

